import { Box3, Color, MathUtils, Plane } from 'three';

import PointCloud from '@giro3d/giro3d/entities/PointCloud';

import { LAYER_TYPES } from 'services/Constants';
import HoveredItem from 'types/HoveredItem';
import {
    Attribute,
    ColoringMode,
    HasAttributes,
    HasColoringMode,
    HasOpacity,
    HasOverlayColor,
    HasProgress,
    IsPointCloud,
    LayerState,
} from 'types/LayerState';
import * as layersSlice from 'redux/layers';
import ColorMap, { evaluateAttributeColorMap } from 'types/ColorMap';
import IsHoverable from '../IsHoverable';
import Layer, { Settings as BaseSettings, ConstructorParams as BaseConstructorParams, LayerEventMap } from '../Layer';
import LayerStateObserver from '../LayerStateObserver';

export interface ConstructorParams extends BaseConstructorParams {
    defaultAttribute: string;
    entity: PointCloud;
    datasetType: LAYER_TYPES;
    readableName: string;
}

type CopcLayerState = LayerState &
    HasProgress &
    HasOpacity &
    IsPointCloud &
    HasOverlayColor &
    HasColoringMode &
    HasAttributes;

export interface Settings extends BaseSettings {
    attribute: string;
    min: number;
    max: number;
    overlayColor: Color;
    colorMapColors: Color[];
}

/**
 * Displays a single COPC LAZ file.
 */
export default class CopcLayer extends Layer<Settings, LayerEventMap, CopcLayerState> implements IsHoverable {
    readonly isHoverable = true as const;

    private readonly _datasetType: LAYER_TYPES;
    private readonly _readableName: string;

    private readonly _entity: PointCloud;

    private _externalMin: number;
    private _externalMax: number;

    constructor(params: ConstructorParams) {
        super(params);

        this._entity = params.entity;
        this._datasetType = params.datasetType;
        this._readableName = params.readableName;

        this.settings.attribute = params.defaultAttribute;
        this.settings.colorMapColors = [];
        this.settings.overlayColor = new Color('white');

        // Temporary workaround to support opacity curves,
        // as currently the material is not set to transparent
        // https://gitlab.com/giro3d/giro3d/-/issues/559
        this._entity.opacity = 0.999;
        this._entity.visible = this.getVisibility();
    }

    // eslint-disable-next-line class-methods-use-this
    hover(): void {
        // Do nothing
    }

    getHoverInfo(): HoveredItem {
        return {
            itemType: this._datasetType,
            name: this._readableName,
        };
    }

    protected override subscribeToStateChanges(observer: LayerStateObserver<CopcLayerState>): void {
        super.subscribeToStateChanges(observer);

        observer.subscribe(layersSlice.getPointCloudSize(this._layerState), (v) => this.setPointSize(v));
        observer.subscribe(layersSlice.getSseThreshold(this._layerState), (v) => this.setSseThreshold(v));
        observer.subscribe(layersSlice.getOpacity(this._layerState), (v) => this.setOpacity(v));
        observer.subscribe(layersSlice.getOverlayColor(this._layerState), (v) => this.setOverlayColor(v));
        observer.subscribe(layersSlice.getCurrentColoringMode(this._layerState), (v) => this.setColoringMode(v));
        observer.subscribe(layersSlice.getActiveAttributeAndColorMap(this._layerState), (v) =>
            this.setColormapProperties(v)
        );
    }

    private setColoringMode(mode: ColoringMode): void {
        switch (mode) {
            case ColoringMode.Colormap:
                this.updateMaterialColorMap({
                    min: this._externalMin,
                    max: this._externalMax,
                    lut: this.settings.colorMapColors,
                });
                break;
            default:
                // The PointCloud entity technically does not support solid colors,
                // so the easiest workaround is to assign a single-color colormap
                this.updateMaterialColorMap({
                    min: this._externalMin,
                    max: this._externalMax,
                    lut: [this.settings.overlayColor],
                });
                break;
        }

        this.notifyLayerChange();
    }

    private setColormapProperties(arg: { attribute: Attribute; colorMap: ColorMap }): void {
        if (arg == null) {
            return;
        }

        const { attribute, colorMap } = arg;

        this.settings.attribute = attribute.id;

        const { lut, opacity, min, max } = evaluateAttributeColorMap(colorMap, attribute, { samples: 256 });

        this.settings.colorMapColors = lut;

        this._externalMin = min;
        this._externalMax = max;

        this.updateMaterialColorMap({
            min: this._externalMin,
            max: this._externalMax,
            lut,
            opacity,
        });

        this.notifyLayerChange();
    }

    private updateMaterialColorMap(props: { min: number; max: number; lut?: Color[]; opacity?: number[] }) {
        const colorMap = this._entity.colorMap;

        colorMap.min = props.min ?? 0;
        colorMap.max = props.max ?? 1;

        if (props.lut) {
            colorMap.colors = props.lut;
        }
        if (props.opacity) {
            colorMap.opacity = props.opacity;
        }
    }

    private setOverlayColor(value: Color) {
        this.settings.overlayColor = value ?? new Color('black');
        // The point cloud entity does not technically support solid colors,
        // so we just apply a single-color colormap.
        this._entity.colorMap.colors = [this.settings.overlayColor];
        this.notifyLayerChange();
    }

    private setPointSize(value: number) {
        this._entity.pointSize = value;
        this.notifyLayerChange();
    }

    private setSseThreshold(value: number) {
        this._entity.subdivisionThreshold = value;
        this.notifyLayerChange();
    }

    private postInitialization() {
        this._entity.setActiveAttribute(this.settings.attribute);

        this.assignInitialValues();
        this.notifyLayerChange();
    }

    protected initOnce(): Promise<void> {
        return this._giro3dInstance.add(this._entity).then(() => this.postInitialization());
    }

    protected setClippingPlane(plane: Plane) {
        this._entity.clippingPlanes = plane ? [plane] : null;
        this.notifyLayerChange();
    }

    setZScale(scale: number) {
        this.settings.zScale = scale;
        const obj = this._entity.object3d;
        obj.scale.set(1, 1, scale);
        obj.updateMatrix();
        obj.updateMatrixWorld(true);
        this.notifyLayerChange();
    }

    getLoading(): boolean {
        return this._entity.loading;
    }

    get3dElement() {
        return this._entity;
    }

    setOpacity(opacity: number): void {
        // Temporary workaround to support opacity curves,
        // as currently the material is not set to transparent
        // https://gitlab.com/giro3d/giro3d/-/issues/559
        opacity = MathUtils.clamp(opacity, 0, 0.999);
        this._entity.opacity = opacity;

        this.notifyLayerChange();
    }

    getOpacity(): number {
        return this._entity.opacity;
    }

    protected async doSetVisibility(visible: boolean): Promise<void> {
        this._entity.visible = visible;
        this.notifyLayerChange();
    }

    protected override onDispose() {
        if (this.initialized) {
            this._giro3dInstance.remove(this._entity);
        }
    }

    getBoundingBox(): Box3 {
        if (!this.initialized) return null;

        return this._entity.getBoundingBox();
    }
}
