// React
import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip } from 'reactstrap';
import { DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';

// Redux
import { Formik } from 'formik';
import Dataset from 'types/Dataset';
import { useAppSelector } from 'store';
import { supportedTypesLabels } from 'services/Labels';
import LayerGroup from 'types/LayerGroup';
import { generateUUID } from 'three/src/math/MathUtils';
import Lazy from 'components/Lazy';
import BaseInput from 'components/forms/BaseInput';
import * as layersSlice from '../../../redux/layers';
import * as datasetsSlice from '../../../redux/datasets';

import { pollForDatasets, reorderDatasets } from '../../../redux/actions';

// Components
import DatasetListGroup from './DatasetListGroup';
import DatasetListItem from './DatasetListItem';
import { PROJECT_ACTION } from '../ProjectListItem';
import ErrorBoundary from '../../ErrorBoundary';
import Select from '../../forms/Select';
import HelpPanel from '../../forms/HelpPanel';
import useInterval from '../../../services/useInterval';
import { LAYER_STATES, LAYER_TYPES } from '../../../services/Constants';
import Library from '../../library/Library';

const GROUP_ACTION = {
    CREATE: 'group_create',
    UPDATE: 'group_update',
    DELETE: 'group_delete',
};

const TABS = { GROUPING: 'grouping', ORDERING: 'ordering' };

const groupDatasets = (datasets: Dataset[], groups: Record<string, LayerGroup>) => {
    const groupedSets: Record<string, Dataset[]> = {};

    Object.values(groups).forEach((group) => {
        groupedSets[group.id] = datasets.filter((d) => group.layers.includes(d.id));
    });

    const groupedDatasetIds = Object.values(groups).flatMap((group) => group.layers);
    const ungroupedDatasets = datasets.filter((d) => !groupedDatasetIds.includes(d.id));

    ungroupedDatasets.forEach((dataset) => {
        if (groupedSets[dataset.type]) groupedSets[dataset.type].push(dataset);
        else groupedSets[dataset.type] = [dataset];
    });

    Object.keys(groupedSets).forEach((key) => {
        groupedSets[key] = groupedSets[key].sort((a, b) => {
            if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
            if (a.name.toUpperCase() > b.name.toUpperCase()) return 1;
            return 0;
        });
    });

    const order = [
        LAYER_TYPES.BATHYMETRY,
        LAYER_TYPES.HORIZON,
        LAYER_TYPES.WATER_COLUMN,

        LAYER_TYPES.BACKSCATTER,
        LAYER_TYPES.SAS,

        LAYER_TYPES.TRACK,
        LAYER_TYPES.EM,
        LAYER_TYPES.ATTRIBUTE,
        LAYER_TYPES.CABLE,
        LAYER_TYPES.PIPELINE,
        LAYER_TYPES.VECTOR,
        LAYER_TYPES.SEISMIC,
        LAYER_TYPES.BOREHOLE,
        LAYER_TYPES.CAMERA,
        LAYER_TYPES.LASER,

        LAYER_TYPES.VESSEL,
        LAYER_TYPES.DOCUMENT,
        LAYER_TYPES.UNKNOWN,
    ];

    const ordering = {};
    order.forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(groupedSets, key)) ordering[key] = null;
    });

    return Object.assign(ordering, groupedSets);
};

const ProjectDatasetsMenu = () => {
    const dispatch = useDispatch();
    const datasets = useAppSelector(datasetsSlice.getProjectDatasets, shallowEqual);
    const groups: Record<string, LayerGroup> = useAppSelector(layersSlice.getGroups);

    const currentProject = useAppSelector(datasetsSlice.currentProject);

    const [currentTab, setCurrentTab] = useState(TABS.GROUPING);

    const [action, setAction] = useState(null);

    const [activeGroup, setActiveGroup] = useState(null);

    const groupedDatasets = groupDatasets(datasets, groups);

    const orderedLayers = useAppSelector(layersSlice.orderedIds, shallowEqual);
    const [datasetsOrdered, setDatasetsOrdered] = useState<Dataset[]>([]);

    const [draggedType, setDraggedType] = useState(null);

    const updateOrdering = () => {
        // We want to display datasets from front to back, reverse the order given by giro3dServer
        const orderableDatasets = datasets.filter((dataset) => orderedLayers.indexOf(dataset.id) !== -1);
        orderableDatasets.sort((a, b) => orderedLayers.indexOf(a.id) - orderedLayers.indexOf(b.id));
        setDatasetsOrdered(orderableDatasets);
    };

    useEffect(updateOrdering, [orderedLayers]);

    useInterval(() => {
        const datasetsInProcessing = datasets.filter((d) =>
            [LAYER_STATES.CONVERTING, LAYER_STATES.DELETING, LAYER_STATES.UPLOADING].includes(d.state)
        );
        if (datasetsInProcessing.length !== 0) dispatch(pollForDatasets(datasetsInProcessing));
    }, 5000);

    const goToTab = (tab) => {
        if (currentTab !== tab) {
            setCurrentTab(tab);
        }
    };

    const handleDragEndSort = (event) => {
        const { active, over } = event;

        if (active.id !== over.id) {
            const oldIndex = orderedLayers.indexOf(active.id);
            const newIndex = orderedLayers.indexOf(over.id);
            const newArray = arrayMove(orderedLayers, oldIndex, newIndex);

            dispatch(reorderDatasets(newArray));
            updateOrdering();
        }
    };

    const handleDragEndGroup = (event) => {
        const { active, over } = event;

        if (Object.values(LAYER_TYPES).includes(over.id)) dispatch(layersSlice.removeLayerFromGroup(active.id));
        else
            dispatch(
                layersSlice.addLayerToGroup({
                    group: over.id,
                    dataset: active.id,
                })
            );

        setDraggedType(null);
    };

    const handleDragOverGroup = (event) => {
        const { active, over } = event;

        if (Object.values(LAYER_TYPES).includes(over?.id))
            setDraggedType(datasets.find((d) => d.id === active.id).type);
        else setDraggedType(null);
    };

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const modalContent = () => {
        switch (action) {
            case PROJECT_ACTION.DATASETS:
                return (
                    <>
                        <ModalHeader toggle={() => setAction(null)}>
                            Select Datasets for {currentProject.name}
                        </ModalHeader>
                        <ModalBody>
                            <Library
                                key={`project-library-${currentProject.id}`}
                                project={currentProject}
                                collectionId={undefined}
                                onCollectionOpen={undefined}
                                onCollectionClose={undefined}
                            />
                        </ModalBody>
                    </>
                );
            case GROUP_ACTION.CREATE:
            case GROUP_ACTION.UPDATE: {
                return (
                    <Formik
                        initialValues={{
                            name: activeGroup?.name || '',
                            datasets:
                                groupedDatasets[activeGroup?.id]?.map((dataset) => ({
                                    'label': dataset.name,
                                    'value': dataset.id,
                                })) || [],
                        }}
                        onSubmit={(values) => {
                            if (activeGroup)
                                dispatch(
                                    layersSlice.updateGroup({
                                        id: activeGroup.id,
                                        name: values.name,
                                        layers: values.datasets.map((option) => option.value),
                                    })
                                );
                            else
                                dispatch(
                                    layersSlice.createGroup({
                                        id: generateUUID(),
                                        name: values.name,
                                        layers: values.datasets.map((option) => option.value),
                                    })
                                );
                            setAction(null);
                            setActiveGroup(null);
                        }}
                        validate={(values) => (values.name ? {} : { name: 'Required' })}
                        enableReinitialize
                    >
                        {({ isSubmitting, values, submitForm, setFieldValue }) => (
                            <>
                                <ModalHeader
                                    toggle={() => {
                                        setAction(null);
                                        setActiveGroup(null);
                                    }}
                                >
                                    {activeGroup ? `Settings for ${activeGroup.name}` : 'Create a new group'}
                                </ModalHeader>
                                <ModalBody>
                                    <HelpPanel>
                                        Custom groups are saved in the project view.
                                        <br />
                                        To retain these settings when you or others open this project, create a project
                                        view link or save a default view.
                                    </HelpPanel>
                                    <BaseInput name="name" label="Group name" titled />
                                    <Select
                                        name="datasets"
                                        isMulti
                                        options={datasets
                                            .sort((a, b) => {
                                                if (a.name < b.name) return -1;
                                                if (a.name > b.name) return 1;
                                                return 0;
                                            })
                                            .map((dataset) => ({
                                                'label': dataset.name,
                                                'value': dataset.id,
                                            }))}
                                        placeholder="Select dataset"
                                        multiValueCounterMessage="Selected dataset"
                                        value={values.datasets}
                                        setFieldValue={setFieldValue}
                                    />
                                </ModalBody>
                                <ModalFooter>
                                    <button
                                        type="submit"
                                        className="pane-button large highlight"
                                        onClick={submitForm}
                                        disabled={isSubmitting}
                                    >
                                        {activeGroup ? 'Update' : 'Create'}
                                    </button>
                                </ModalFooter>
                            </>
                        )}
                    </Formik>
                );
            }
            case GROUP_ACTION.DELETE:
                return (
                    <>
                        <ModalBody>
                            <i className="modal-icon modal-icon-bad fal fa-circle-xmark no-hover" />
                            <span className="big-modal-text">Are you sure?</span>
                            <button
                                type="button"
                                className="pane-button large highlight"
                                onClick={() => {
                                    dispatch(layersSlice.deleteGroup(activeGroup.id));
                                    setAction(null);
                                    setActiveGroup(null);
                                }}
                            >
                                Yes, Delete this Group
                            </button>
                        </ModalBody>
                        <ModalFooter>
                            <button
                                type="button"
                                className="pane-button large"
                                onClick={() => {
                                    setAction(null);
                                    setActiveGroup(null);
                                }}
                            >
                                Cancel
                            </button>
                        </ModalFooter>
                    </>
                );
            default:
                return null;
        }
    };
    return (
        <ErrorBoundary
            dispatch={dispatch}
            fallback={
                <div className="tabContent">
                    <span className="error-fallback-message">
                        <i className="fal fa-exclamation-triangle icon-red" />
                        An error occured in this tab.
                        <i className="fal fa-exclamation-triangle icon-red" />
                    </span>
                </div>
            }
        >
            <div className="tabContent">
                <div className="input-row">
                    <button
                        type="button"
                        className={`pane-button ${currentTab === TABS.GROUPING ? 'selected' : ''}`}
                        onClick={() => goToTab(TABS.GROUPING)}
                    >
                        Grouping
                    </button>
                    <button
                        type="button"
                        className={`pane-button ${currentTab === TABS.ORDERING ? 'selected' : ''}`}
                        onClick={() => goToTab(TABS.ORDERING)}
                    >
                        Ordering
                    </button>
                    <div className="grow" />
                    <button
                        type="button"
                        className="pane-button highlight"
                        onClick={() => {
                            setAction(PROJECT_ACTION.DATASETS);
                        }}
                        disabled={!currentProject?.user_permissions.update_project}
                        title="Add Datasets"
                        id="add-datasets"
                    >
                        <i className="fas fa-plus" />
                    </button>
                    {!currentProject?.user_permissions.update_project ? (
                        <UncontrolledTooltip target="add-datasets">
                            You lack the permissions to add datasets to this project.
                        </UncontrolledTooltip>
                    ) : null}
                </div>
                <ul className="list">
                    <Lazy visible={currentTab === TABS.GROUPING}>
                        <DndContext
                            sensors={sensors}
                            onDragEnd={handleDragEndGroup}
                            onDragOver={handleDragOverGroup}
                            autoScroll={false}
                        >
                            {Object.keys(groupedDatasets).map((id) => (
                                <DatasetListGroup
                                    key={`group-${id}`}
                                    datasetIds={groupedDatasets[id].map((d) => d.id)}
                                    groupId={id}
                                    groupName={supportedTypesLabels[id] || groups[id].name}
                                    customGroup={!supportedTypesLabels[id]}
                                    setSettingsModal={() => {
                                        setActiveGroup(groups[id]);
                                        setAction(GROUP_ACTION.UPDATE);
                                    }}
                                    setDeleteModal={() => {
                                        setActiveGroup(groups[id]);
                                        setAction(GROUP_ACTION.DELETE);
                                    }}
                                    highlight={draggedType === id}
                                />
                            ))}
                        </DndContext>
                    </Lazy>
                    <Lazy visible={currentTab === TABS.ORDERING}>
                        <DndContext sensors={sensors} onDragEnd={handleDragEndSort} autoScroll={false}>
                            <SortableContext
                                id="datasets-sortable"
                                items={orderedLayers}
                                strategy={verticalListSortingStrategy}
                            >
                                {datasetsOrdered
                                    .map((item) => (
                                        <DatasetListItem
                                            key={`orderable-${item.id}`}
                                            id={`orderable-${item.id}`}
                                            datasetId={item.id}
                                            groupOpen
                                            handleMode="sort"
                                        />
                                    ))
                                    .reverse()}
                            </SortableContext>
                        </DndContext>
                    </Lazy>
                </ul>
                <button
                    type="button"
                    className="pane-button"
                    onClick={() => setAction(GROUP_ACTION.CREATE)}
                    hidden={currentTab !== TABS.GROUPING}
                >
                    <i className="fal fa-plus" />
                    Add Custom Group
                </button>
            </div>
            <Modal
                id="project-data"
                isOpen={action !== null}
                className={`${action === GROUP_ACTION.DELETE ? 'modal-confirm' : ''} ${action === PROJECT_ACTION.DATASETS ? 'wide' : ''}`}
            >
                {modalContent()}
            </Modal>
        </ErrorBoundary>
    );
};

export default ProjectDatasetsMenu;
