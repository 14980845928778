import {
    Actions,
    BorderNode,
    DockLocation,
    IJsonModel,
    ITabRenderValues,
    ITabSetRenderValues,
    Layout,
    Model,
    Node,
    RowNode,
    TabNode,
    TabSetNode,
} from 'flexlayout-react';
import 'flexlayout-react/style/dark.css';
import store, { useAppDispatch, useAppSelector } from 'store';
import {
    fetchCollections,
    fetchOrganizations,
    fetchProject,
    fetchProjections,
    loadGiro3d,
    selectPage,
    unloadGiro3d,
    updateLastSessionView,
} from 'redux/actions';
import { getUppyShown } from 'redux/selectors';
import { PAGE, POSITION, PANE, PANE_PROPERTIES, PANE_TYPE } from 'services/Constants';
import {
    CreateAnnotationPane,
    CreateDataPane,
    CreateDatasetConfigPane,
    CreateDatasetPane,
    CreatePane,
    CreateStoryPane,
    UpdateDataPane,
    useEventBus,
} from 'EventBus';
import { useMountEffect } from 'components/utils';
import { DatasetId, QueryParameters } from 'types/common';
import * as layoutSlice from 'redux/layout';
import * as giro3dSlice from 'redux/giro3d';
import * as datasetsSlice from 'redux/datasets';
import * as annotationsSlice from 'redux/annotations';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import UppyService from 'services/UppyService';
import HeaderButton from 'components/flexLayout/HeaderButton';
import StateObserver from 'giro3d_extensions/layers/StateObserver';
import {
    allowDropBuilder,
    findFirstNodeWithComponent,
    getLastValidTabset,
    onModelChangeBuilder,
    openSide,
    tabSetPlaceHolder,
    AddNewTabButton,
} from 'components/flexLayout/CommonFunctions';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import giro3dService from 'services/Giro3dService';
import { AnnotationId } from 'types/Annotation';
import { loadView, serialize } from 'services/serializer';
import Giro3D from './Giro3D';
import ContextMenu from './contextMenu/ContextMenu';
import Giro3DUI from './Giro3DUI';
import DragAndDropArea from './DragAndDropArea';
import ElevationProfile from './ElevationProfileMenu/ElevationProfile';
import InstanceLoadingIndicator from './StatusBar/InstanceLoadingIndicator';
import AttributeTable from './attributesMenu/AttributeTable';
import PinnedLegendList from './pinnedLegend/PinnedLegendList';
import Toolbar from './Toolbar';
import AnnotationMenu from './annotationMenu/AnnotationMenu';
import ProjectDatasetsMenu from './datasetsMenu/ProjectDatasetsMenu';
import InspectDatasetMenu from './datasetsMenu/sourceFilesMenu/InspectDatasetMenu';
import AnnotationViewer from './annotationMenu/AnnotationViewer';
import UploadsMenu from '../uploads/UploadsMenu';
import MeasureMenu from './measureMenu/MeasureMenu';
import GridSetting from './settingsMenu/GridSetting';
import HillshadeSetting from './settingsMenu/HillshadingSetting';
import PointCloudSettings from './settingsMenu/PointCloudSettings';
import AdvancedSetting from './settingsMenu/AdvancedSetting';
import ZScaleSetting from './settingsMenu/ZScaleSetting';
import CameraModeSetting from './settingsMenu/CameraModeSetting';
import FeatureInfo from './featureInfo/FeatureInfo';
import ActiveSelection from './ActiveSelection';
import AnnotationTool from './annotationMenu/AnnotationTool';
import ViewMenu from './viewMenu/ViewMenu';
import AddDataset from './datasetsMenu/AddDataset';
import Minimap from './minimap/Minimap';
import SeismicViewer from './seismic/SeismicViewer';
import ChartMenu from './chartMenu/ChartMenu';
import PerspectiveCameraSetting from './settingsMenu/PerspectiveCameraSetting';
import { ViewStory } from './viewMenu/StoryMap';

const ModelContext = createContext(null);
export function useModelContext(): Model {
    return useContext(ModelContext);
}

const json: IJsonModel = {
    global: {
        tabSetHeaderHeight: 24,
        tabSetTabStripHeight: 18,
        splitterSize: 4,
        rootOrientationVertical: false,
        tabEnableRename: false,
        enableEdgeDock: false,
        tabSetEnableDeleteWhenEmpty: false,
        tabSetEnableDrag: false,
    },
    borders: [],
    layout: {
        type: 'row',
        children: [
            {
                type: 'tabset',
                enableDrag: false,
                width: -4,
                weight: 1,
                children: [],
            },
            {
                type: 'row',
                weight: 3,
                children: [
                    {
                        type: 'row',
                        weight: 2,
                        children: [
                            {
                                type: 'tabset',
                                name: 'Viewport',
                                classNameTabStrip: 'tabless',
                                tabStripHeight: 0.00001,
                                weight: 2,
                                enableDrag: false,
                                enableDrop: false,
                                enableMaximize: false,
                                children: [
                                    {
                                        type: 'tab',
                                        component: PANE.GIRO3D_VIEWPORT,
                                    },
                                ],
                            },
                            {
                                type: 'tabset',
                                enableDrag: false,
                                width: -4,
                                weight: 1,
                                children: [],
                            },
                        ],
                    },
                    {
                        type: 'tabset',
                        enableDrag: false,
                        height: -4,
                        weight: 1,
                        children: [],
                    },
                ],
            },
        ],
    },
};

function getSide(node: Node): POSITION {
    const rootChildren = node.getModel().getRoot().getChildren();
    const parent = node.getParent();

    if (node === rootChildren[0] || parent === rootChildren[0]) return POSITION.LEFT;

    const rightNode = rootChildren[1].getChildren()[0].getChildren()[1];
    if (node === rightNode || parent === rightNode) return POSITION.RIGHT;

    const trayNode = rootChildren[1].getChildren()[1];
    if (node === trayNode || parent === trayNode) return POSITION.TRAY;

    return undefined;
}

function getSideNode(position: POSITION, m: Model): Node {
    switch (position) {
        case POSITION.LEFT:
            return m.getRoot().getChildren()[0];
        case POSITION.RIGHT:
            return m.getRoot().getChildren()[1].getChildren()[0].getChildren()[1];
        case POSITION.TRAY:
            return m.getRoot().getChildren()[1].getChildren()[1];
        case POSITION.CENTER:
            return m.getRoot().getChildren()[1].getChildren()[0].getChildren()[0];
        default:
            return undefined;
    }
}

function getQueryStringParams(query: string): QueryParameters {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query).split('&').reduce((params, param) => {
              const [key, value] = param.split('=');
              params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
              return params;
          }, {})
        : {};
}

const Project = () => {
    const giro3dRef = useRef<HTMLDivElement>(null);
    const giro3dMinimapRef = useRef(null);
    const giro3dSeismicRef = useRef(null);

    const inspectorRef = useRef(null);

    const giro3dControlsRef = useRef(null);

    const eventBus = useEventBus();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const stateObserver = new StateObserver(store.getState, store.subscribe);

    const { id: projectId } = useParams();
    const project = useAppSelector(datasetsSlice.currentProject);

    const leftOpen = useAppSelector(layoutSlice.getLeftOpen);
    const rightOpen = useAppSelector(layoutSlice.getRightOpen);
    const trayOpen = useAppSelector(layoutSlice.getTrayOpen);

    const coordinatesShown = useAppSelector(giro3dSlice.getCoordinatesShown);
    const axisCompassShown = useAppSelector(giro3dSlice.getAxisCompassShown);

    const promptedView = useAppSelector(giro3dSlice.getPromptedView);

    const [addDataset, setAddDataset] = useState(false);
    const [model, setModel] = useState(Model.fromJson(json));

    const state = useAppSelector((s) => s);

    const modelValueRef = useRef(model);
    const projectValueRef = useRef(project);
    const stateValueRef = useRef(state);

    useEffect(() => {
        modelValueRef.current = model;
    }, [model]);

    useEffect(() => {
        projectValueRef.current = project;
    }, [project]);

    useEffect(() => {
        stateValueRef.current = state;
    }, [state]);

    let autoSaveInterval: NodeJS.Timer = null;

    const overwriteModel = (event) => {
        const rows = event.jsonModel;
        // We have no checking of the structure of the json on the backend so we check that its valid here
        // Its still possible to get attributes in that we don't want but without parsing the whole thing
        // recursively, we will miss some things.
        if (
            rows.type === 'row' &&
            rows.children.length === 2 &&
            rows.children[1].type === 'row' &&
            rows.children[1].children.length === 2 &&
            rows.children[1].children[0].type === 'row' &&
            rows.children[1].children[0].children.length === 2 &&
            rows.children[1].children[0].children[0].name === 'Viewport' &&
            rows.children[1].children[0].children[0].children.length === 1 &&
            rows.children[1].children[0].children[0].children[0].component === 'giro3d'
        ) {
            setModel(Model.fromJson({ ...json, layout: rows }));
            giro3dService.updateRefs(inspectorRef.current, giro3dControlsRef.current);
        } else throw new Error('Invalid layout JSON');
    };

    useEffect(() => {
        subscribe();
        return unsubscribe;
    }, [model]);

    const autoSave = () => {
        dispatch(
            updateLastSessionView({
                name: 'Last Session',
                project_id: projectValueRef.current.id,
                view: serialize(stateValueRef.current, modelValueRef.current),
            })
        );
    };

    useMountEffect(
        () => {
            eventBus.subscribe('overwrite-model', overwriteModel);
            autoSaveInterval = setInterval(autoSave, 2 * 60 * 1000);
        },
        () => {
            clearInterval(autoSaveInterval);
            eventBus.unsubscribe('overwrite-model', overwriteModel);
            dispatch(datasetsSlice.setCurrentProject(undefined));
            autoSave();
        }
    );

    model.setOnAllowDrop(allowDropBuilder(getSide));

    const classNameMapper = (defaultClassName: string): string => {
        switch (defaultClassName) {
            case 'flexlayout__layout':
                return `flexlayout__layout project__layout ${leftOpen ? '' : 'left-closed'} ${rightOpen ? '' : 'right-closed'} ${trayOpen ? '' : 'tray-closed'}`;
            default:
                return defaultClassName;
        }
    };

    /**
     * A pane that can have no duplicates
     */
    const createNewPane = (props: CreatePane) => {
        if (PANE_PROPERTIES[props.paneType].type !== PANE_TYPE.SINGLE) {
            console.error('Cannot create Single pane with', props.paneType);
            return;
        }

        const existingPane = findFirstNodeWithComponent(model.getRoot(), props.paneType);
        if (existingPane) {
            if (props.showExisting) {
                model.doAction(Actions.selectTab(existingPane.getId()));
                openSide(getSide(existingPane), dispatch);
            }
            return;
        }

        model.doAction(
            Actions.addNode(
                {
                    type: 'tab',
                    name: PANE_PROPERTIES[props.paneType].name,
                    component: props.paneType,
                },
                props.tabsetId ??
                    getLastValidTabset(getSideNode(PANE_PROPERTIES[props.paneType].defaultPosition, model)).getId(),
                DockLocation.CENTER,
                -1,
                true
            )
        );
        openSide(
            props.tabsetId
                ? getSide(model.getNodeById(props.tabsetId))
                : PANE_PROPERTIES[props.paneType].defaultPosition,
            dispatch
        );
    };

    /**
     * A pane that can exist once per dataset
     */
    const createNewDatasetPane = (props: CreateDatasetPane) => {
        if (PANE_PROPERTIES[props.paneType].type !== PANE_TYPE.DATASET) {
            console.error('Cannot create Dataset pane with', props.paneType);
            return;
        }

        const existingPane = findFirstNodeWithComponent(model.getRoot(), props.paneType, props.datasetId);
        if (existingPane) {
            if (props.showExisting) {
                model.doAction(Actions.selectTab(existingPane.getId()));
                openSide(getSide(existingPane), dispatch);
            }
            return;
        }

        model.doAction(
            Actions.addNode(
                {
                    type: 'tab',
                    name: PANE_PROPERTIES[props.paneType].labeledName(
                        stateObserver.select(datasetsSlice.get(props.datasetId)).name
                    ),
                    component: props.paneType,
                    config: props.datasetId,
                },
                props.tabsetId ??
                    getLastValidTabset(getSideNode(PANE_PROPERTIES[props.paneType].defaultPosition, model)).getId(),
                DockLocation.CENTER,
                -1,
                true
            )
        );
        openSide(
            props.tabsetId
                ? getSide(model.getNodeById(props.tabsetId))
                : PANE_PROPERTIES[props.paneType].defaultPosition,
            dispatch
        );
    };

    /**
     * A pane that can exist once per dataset with configuration
     */
    const createNewDatasetConfigPane = (props: CreateDatasetConfigPane) => {
        if (PANE_PROPERTIES[props.paneType].type !== PANE_TYPE.DATASET_CONFIG) {
            console.error('Cannot create Dataset-Config pane with', props.paneType);
            return;
        }

        const existingPane = findFirstNodeWithComponent(model.getRoot(), props.paneType, props.data.datasetId);
        if (existingPane) {
            if (props.showExisting) {
                model.doAction(Actions.selectTab(existingPane.getId()));
                openSide(getSide(existingPane), dispatch);
            }
            return;
        }

        model.doAction(
            Actions.addNode(
                {
                    type: 'tab',
                    name: PANE_PROPERTIES[props.paneType].labeledName(
                        stateObserver.select(datasetsSlice.get(props.data.datasetId)).name
                    ),
                    component: props.paneType,
                    config: props.data,
                },
                props.tabsetId ??
                    getLastValidTabset(getSideNode(PANE_PROPERTIES[props.paneType].defaultPosition, model)).getId(),
                DockLocation.CENTER,
                -1,
                true
            )
        );
        openSide(
            props.tabsetId
                ? getSide(model.getNodeById(props.tabsetId))
                : PANE_PROPERTIES[props.paneType].defaultPosition,
            dispatch
        );
    };

    /**
     * A pane that can exist once per annotation
     */
    const createNewAnnotationPane = (props: CreateAnnotationPane) => {
        if (PANE_PROPERTIES[props.paneType].type !== PANE_TYPE.ANNOTATION) {
            console.error('Cannot create Annotation pane with', props.paneType);
            return;
        }

        const existingPane = findFirstNodeWithComponent(model.getRoot(), props.paneType, props.annotationId);
        if (existingPane) {
            if (props.showExisting) {
                model.doAction(Actions.selectTab(existingPane.getId()));
                openSide(getSide(existingPane), dispatch);
            }
            return;
        }

        model.doAction(
            Actions.addNode(
                {
                    type: 'tab',
                    name: PANE_PROPERTIES[props.paneType].labeledName(
                        stateObserver.select(annotationsSlice.get(props.annotationId)).name
                    ),
                    component: props.paneType,
                    config: props.annotationId,
                },
                props.tabsetId ??
                    getLastValidTabset(getSideNode(PANE_PROPERTIES[props.paneType].defaultPosition, model)).getId(),
                DockLocation.CENTER,
                -1,
                true
            )
        );
        openSide(
            props.tabsetId
                ? getSide(model.getNodeById(props.tabsetId))
                : PANE_PROPERTIES[props.paneType].defaultPosition,
            dispatch
        );
    };

    /**
     * A pane that can exist once per story
     */
    const createNewStoryPane = (props: CreateStoryPane) => {
        if (PANE_PROPERTIES[props.paneType].type !== PANE_TYPE.STORY) {
            console.error('Cannot create Story pane with', props.paneType);
            return;
        }

        const existingPane = findFirstNodeWithComponent(model.getRoot(), props.paneType, props.storyId);
        if (existingPane) {
            if (props.showExisting) {
                model.doAction(Actions.selectTab(existingPane.getId()));
                openSide(getSide(existingPane), dispatch);
            }
            return;
        }

        model.doAction(
            Actions.addNode(
                {
                    type: 'tab',
                    name: PANE_PROPERTIES[props.paneType].labeledName(
                        stateObserver.select(giro3dSlice.getStory(props.storyId)).name
                    ),
                    component: props.paneType,
                    config: props.storyId,
                },
                props.tabsetId ??
                    getLastValidTabset(getSideNode(PANE_PROPERTIES[props.paneType].defaultPosition, model)).getId(),
                DockLocation.CENTER,
                -1,
                true
            )
        );
        openSide(
            props.tabsetId
                ? getSide(model.getNodeById(props.tabsetId))
                : PANE_PROPERTIES[props.paneType].defaultPosition,
            dispatch
        );
    };

    /**
     * A pane that has some internal data
     */
    const createNewDataPane = (props: CreateDataPane) => {
        if (PANE_PROPERTIES[props.paneType].type !== PANE_TYPE.DATA) {
            console.error('Cannot create Data pane with', props.paneType);
            return;
        }

        model.doAction(
            Actions.addNode(
                {
                    type: 'tab',
                    name: PANE_PROPERTIES[props.paneType].name,
                    component: props.paneType,
                    config: props.data,
                },
                props.tabsetId ??
                    getLastValidTabset(getSideNode(PANE_PROPERTIES[props.paneType].defaultPosition, model)).getId(),
                DockLocation.CENTER,
                -1,
                true
            )
        );
        openSide(
            props.tabsetId
                ? getSide(model.getNodeById(props.tabsetId))
                : PANE_PROPERTIES[props.paneType].defaultPosition,
            dispatch
        );
    };

    const updateDataPane = (props: UpdateDataPane) => {
        model.doAction(Actions.updateNodeAttributes(props.tabId, { config: props.data }));
    };

    const getDatasetPanes = (datasetId: DatasetId) => {
        const panes = [];
        model.visitNodes((node) => {
            if (
                node.getType() === 'tab' &&
                [PANE_TYPE.DATASET, PANE_TYPE.DATASET_CONFIG].includes(
                    PANE_PROPERTIES[(node as TabNode).getComponent()].type
                ) &&
                ((node as TabNode).getConfig() === datasetId || (node as TabNode).getConfig().datasetId === datasetId)
            )
                panes.push(node.getId());
        });
        return panes;
    };

    const closeDatasetPanes = (props: { datasetId: DatasetId }) => {
        getDatasetPanes(props.datasetId).forEach((tabId) => model.doAction(Actions.deleteTab(tabId)));
    };

    const getAnnotationPanes = (annotationId: AnnotationId) => {
        const panes = [];
        model.visitNodes((node) => {
            if (
                node.getType() === 'tab' &&
                PANE_TYPE.ANNOTATION === PANE_PROPERTIES[(node as TabNode).getComponent()].type &&
                (node as TabNode).getConfig() === annotationId
            )
                panes.push(node.getId());
        });
        return panes;
    };

    const closeAnnotationPanes = (props: { annotationId: AnnotationId }) => {
        getAnnotationPanes(props.annotationId).forEach((tabId) => model.doAction(Actions.deleteTab(tabId)));
    };

    function subscribe() {
        eventBus.subscribe('create-pane', createNewPane);
        eventBus.subscribe('create-data-pane', createNewDataPane);
        eventBus.subscribe('create-dataset-pane', createNewDatasetPane);
        eventBus.subscribe('create-dataset-config-pane', createNewDatasetConfigPane);
        eventBus.subscribe('create-annotation-pane', createNewAnnotationPane);
        eventBus.subscribe('create-story-pane', createNewStoryPane);
        eventBus.subscribe('update-data-pane', updateDataPane);
        eventBus.subscribe('update-dataset-config-pane', updateDataPane);
        eventBus.subscribe('close-dataset-panes', closeDatasetPanes);
        eventBus.subscribe('close-annotation-panes', closeAnnotationPanes);
        dispatch(fetchProjections());
        dispatch(fetchCollections());
        dispatch(fetchOrganizations());
    }

    function unsubscribe() {
        eventBus.unsubscribe('create-pane', createNewPane);
        eventBus.unsubscribe('create-data-pane', createNewDataPane);
        eventBus.unsubscribe('create-dataset-pane', createNewDatasetPane);
        eventBus.unsubscribe('create-dataset-config-pane', createNewDatasetConfigPane);
        eventBus.unsubscribe('create-annotation-pane', createNewAnnotationPane);
        eventBus.unsubscribe('update-data-pane', updateDataPane);
        eventBus.unsubscribe('update-dataset-config-pane', updateDataPane);
        eventBus.unsubscribe('close-dataset-panes', closeDatasetPanes);
        eventBus.unsubscribe('close-annotation-panes', closeAnnotationPanes);
    }

    const queryParams = getQueryStringParams(useLocation().search);

    const pinTab = (node: TabNode) => {
        const selection = stateObserver.select(giro3dSlice.getSelection);

        switch (node.getComponent()) {
            case PANE.SELECTION:
                switch (selection.type) {
                    case 'annotation':
                        createNewAnnotationPane({
                            paneType: PANE.ANNOTATION,
                            showExisting: true,
                            annotationId: selection.objectId,
                            index: node.getParent().getChildren().indexOf(node),
                        });
                        break;
                    case 'dataset':
                        createNewDatasetPane({
                            paneType: PANE.INSPECTOR,
                            showExisting: true,
                            datasetId: selection.objectId,
                            index: node.getParent().getChildren().indexOf(node),
                        });
                        break;
                    case 'feature':
                        createNewPane({
                            paneType: PANE.FEATURE_INFO,
                            showExisting: true,
                            index: node.getParent().getChildren().indexOf(node),
                        });
                        break;
                    default:
                        break;
                }
                break;
            default:
                console.error('Unrecognised tab pinning:', node.getComponent());
        }
    };

    const factory = (node: TabNode) => {
        const component = node.getComponent();
        const config = node.getConfig();

        switch (component) {
            case PANE.GIRO3D_VIEWPORT:
                return <Giro3DUI inspectorRef={inspectorRef} giro3dControlsRef={giro3dControlsRef} />;
            case PANE.ELEVATION_PROFILE_CHART:
                return <ElevationProfile points={config.points} elevations={config.elevations} tabId={node.getId()} />;
            case PANE.ATTRIBUTE_TABLE:
                return <AttributeTable datasetId={config} />;
            case PANE.LEGEND:
                return <PinnedLegendList />;
            case PANE.DATASETS:
                return <ProjectDatasetsMenu />;
            case PANE.INSPECTOR:
                return <InspectDatasetMenu datasetId={config} />;
            case PANE.ANNOTATIONS:
                return <AnnotationMenu />;
            case PANE.ANNOTATION:
                return <AnnotationViewer annotationId={config} pinned />;
            case PANE.ANNOTATION_TOOL:
                return <AnnotationTool />;
            case PANE.UPLOADS:
                return <UploadsMenu />;
            case PANE.MEASURE:
                return <MeasureMenu />;
            case PANE.FEATURE_INFO:
                return <FeatureInfo />;
            case PANE.SELECTION:
                return <ActiveSelection />;
            case PANE.VIEWS:
                return <ViewMenu />;
            case PANE.MINIMAP:
                return <Minimap minimapRef={giro3dMinimapRef} inspectorRef={inspectorRef} />;
            case PANE.SEISMIC:
                return <SeismicViewer seismicRef={giro3dSeismicRef} inspectorRef={inspectorRef} />;
            case PANE.CHART:
                return <ChartMenu config={config} tabId={node.getId()} />;
            case PANE.STORY:
                return <ViewStory storyId={config} />;
            default:
                console.error('Unrecognised tab component:', component);
                return component;
        }
    };

    const onRenderTabset = (node: TabSetNode | BorderNode, renderValues: ITabSetRenderValues) => {
        if (node instanceof TabSetNode) {
            switch (node.getName()) {
                case 'Viewport':
                    renderValues.headerContent = <InstanceLoadingIndicator />;
                    // Add toolbar buttons here.
                    renderValues.headerButtons.push([
                        <PerspectiveCameraSetting key="perspective-mode" />,
                        <CameraModeSetting key="camera-modes" viewport={giro3dRef.current} />,
                        <AdvancedSetting key="advanced-settings" />,
                        <GridSetting key="grid-settings" />,
                        <HillshadeSetting key="hillshade-settings" />,
                        <PointCloudSettings key="pointcloud-settings" />,
                        <ZScaleSetting key="z-scale-settings" />,
                        <HeaderButton
                            key="axisCompass"
                            toggle={{
                                checked: axisCompassShown,
                                onChange: (v) => dispatch(giro3dSlice.setAxisCompassShown(v)),
                                icon: 'fas fa-asterisk',
                                name: 'Axes',
                            }}
                        />,
                        <HeaderButton
                            key="coordinates"
                            toggle={{
                                checked: coordinatesShown,
                                onChange: (v) => dispatch(giro3dSlice.setCoordinatesShown(v)),
                                icon: 'fas fa-location-dot',
                                name: 'Coordinates',
                            }}
                        />,
                    ]);
                    break;
                default:
                    renderValues.buttons = [
                        <AddNewTabButton
                            key={`add-tab-${node.getId()}`}
                            node={node}
                            side={getSide(node)}
                            eventBus={eventBus}
                        />,
                    ];
            }
        }
    };

    const onRenderTab = (node: TabNode, renderValues: ITabRenderValues) => {
        if (PANE_PROPERTIES[node.getComponent()].pinnable)
            renderValues.buttons.push(
                <button
                    key="addTab"
                    id={`add-${node.getId().replace(/#/g, '')}`}
                    type="button"
                    className="pinTabButton"
                    title="Pin Tab"
                    aria-label="Pin Tab"
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={() => pinTab(node)}
                />
            );
    };

    useEffect(() => {
        const left = getSideNode(POSITION.LEFT, model);
        if ((left as RowNode | TabSetNode).getWidth() === -4 && leftOpen)
            model.doAction(Actions.updateNodeAttributes(left.getId(), { width: undefined }));
        if ((left as RowNode | TabSetNode).getWidth() !== -4 && !leftOpen)
            model.doAction(Actions.updateNodeAttributes(left.getId(), { width: -4 }));
    }, [leftOpen]);

    useEffect(() => {
        const right = getSideNode(POSITION.RIGHT, model);
        if ((right as RowNode | TabSetNode).getWidth() === -4 && rightOpen)
            model.doAction(Actions.updateNodeAttributes(right.getId(), { width: undefined }));
        if ((right as RowNode | TabSetNode).getWidth() !== -4 && !rightOpen)
            model.doAction(Actions.updateNodeAttributes(right.getId(), { width: -4 }));
    }, [rightOpen]);

    useEffect(() => {
        const tray = getSideNode(POSITION.TRAY, model);
        if ((tray as RowNode | TabSetNode).getHeight() === -4 && trayOpen)
            model.doAction(Actions.updateNodeAttributes(tray.getId(), { height: undefined }));
        if ((tray as RowNode | TabSetNode).getHeight() !== -4 && !trayOpen)
            model.doAction(Actions.updateNodeAttributes(tray.getId(), { height: -4 }));
    }, [trayOpen]);

    // Load data from API
    useEffect(() => {
        if (projectId) {
            model.visitNodes((node) => {
                if (node.getType() === 'tab' && (node as TabNode).getComponent() !== PANE.GIRO3D_VIEWPORT)
                    model.doAction(Actions.deleteTab(node.getId()));
            });

            createNewPane({ paneType: PANE.DATASETS, showExisting: false });
            createNewPane({ paneType: PANE.ANNOTATIONS, showExisting: false });

            model.doAction(Actions.selectTab(findFirstNodeWithComponent(model.getRoot(), PANE.DATASETS).getId()));

            dispatch(fetchProject(projectId, true, navigate));
            dispatch(selectPage(PAGE.PROJECT));
        } else {
            dispatch(selectPage(PAGE.OVERVIEW));
            navigate('/');
        }
    }, [projectId]);

    // Trigger init of giro3d
    useEffect(() => {
        if (!giro3dRef.current) return undefined;

        if (project && project.geometry && project.id === projectId) {
            dispatch(
                loadGiro3d(giro3dRef.current, inspectorRef.current, giro3dControlsRef.current, project, queryParams)
            );
            return () => dispatch(unloadGiro3d());
        }

        return undefined;
    }, [project?.id]);

    useEffect(() => {
        document.title = `${project ? project.name : 'Loading Project...'} | SCOPE`;
    }, [project]);

    const uppyShown = useAppSelector(getUppyShown);

    const onFilesDrop = (files) => {
        UppyService.getInstance().addFiles(files);
        setAddDataset(true);
    };

    const modalContent = () => (
        <>
            <ModalHeader toggle={() => setAddDataset(false)}>Add Dataset to {project?.name}</ModalHeader>
            <AddDataset
                key="drag-drop-add-dataset"
                project={project}
                collectionOpen={undefined}
                onClose={() => setAddDataset(false)}
            />
        </>
    );

    const promptModalContent = () => (
        <>
            <ModalBody>
                <i className="modal-icon modal-icon-good fal fa-circle-info no-hover" />
                <span className="big-modal-text">Do you want to resume your last session?</span>
                <span className="small-modal-text">
                    Your last session was autosaved on {new Date(`${promptedView?.updated_at}Z`)?.toLocaleString()}
                </span>
            </ModalBody>
            <ModalFooter>
                <button
                    type="button"
                    className="pane-button large highlight"
                    onClick={() => {
                        loadView(promptedView.view, promptedView.id, dispatch);
                        dispatch(giro3dSlice.setPromptedView());
                    }}
                >
                    Resume Last Session
                </button>
                <button
                    type="button"
                    className="pane-button large"
                    onClick={() => dispatch(giro3dSlice.setPromptedView())}
                >
                    Continue to Default View
                </button>
            </ModalFooter>
        </>
    );

    return (
        <div className="project-space">
            <Toolbar />
            <div className="layout-space">
                <Giro3D giro3dRef={giro3dRef} />
                <ModelContext.Provider value={model}>
                    <Layout
                        model={model}
                        factory={factory}
                        onRenderTabSet={onRenderTabset}
                        onModelChange={onModelChangeBuilder(getSideNode)}
                        classNameMapper={classNameMapper}
                        onTabSetPlaceHolder={tabSetPlaceHolder}
                        onRenderTab={onRenderTab}
                    />
                </ModelContext.Provider>
            </div>
            <ContextMenu />
            <DragAndDropArea showFeedback={!uppyShown} onDrop={onFilesDrop} />
            <Modal isOpen={addDataset}>{modalContent()}</Modal>
            <Modal centered className="modal-confirm" isOpen={promptedView !== undefined}>
                {promptModalContent()}
            </Modal>
        </div>
    );
};

export default Project;
