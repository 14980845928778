import { Timestamp, UUID } from 'types/common';
import { User } from 'types/User';
import { Dispatch } from 'store';
import { checkForUsernames } from '../redux/actions';

export function formatMentions(content, dispatch: Dispatch, usernames: User[]) {
    const matches = [
        ...content.matchAll(
            /@\(([0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}) \| .*?\)/g
        ),
    ];
    dispatch(checkForUsernames(matches.map((match) => match[1])));
    const inbetweens = [
        content.split(
            /@\([0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12} \| .*?\)/g
        ),
    ];
    const finalContent = [inbetweens[0][0]];
    for (let index = 0; index < matches.length; index++) {
        const tagged = usernames ? usernames.find((x) => x.id === matches[index][1]) : null;
        finalContent.push(
            tagged ? (
                <mark key={`mark-${index}`}>{`@${tagged.given_name} ${tagged.family_name}`}</mark>
            ) : (
                matches[index][0]
            )
        );
        finalContent.push(inbetweens[0][index + 1]);
    }
    return finalContent;
}

export function getUsername(id: UUID, users: User[]): string {
    if (users) {
        const match = users.filter((user) => user.id === id);
        if (match.length === 1) return `${match[0].given_name} ${match[0].family_name}`;
    }
    return 'Unknown User';
}

export function createDateReadout(input: Timestamp): string {
    // The provided timestamp is UTC but is assumed to be local so we must convert
    const date = new Date(input);
    const tick = new Date(
        Date.UTC(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            date.getHours(),
            date.getMinutes(),
            date.getSeconds()
        )
    ).valueOf();

    const difference = Date.now() - tick;

    const years = Math.floor(difference / (365 * 24 * 60 * 60 * 1000));
    if (years > 0) return `${years} year${years === 1 ? '' : 's'}`;
    const months = Math.floor(difference / (30 * 24 * 60 * 60 * 1000));
    if (months > 0) return `${months} month${months === 1 ? '' : 's'}`;
    const weeks = Math.floor(difference / (7 * 24 * 60 * 60 * 1000));
    if (weeks > 0) return `${weeks} week${weeks === 1 ? '' : 's'}`;
    const days = Math.floor(difference / (24 * 60 * 60 * 1000));
    if (days > 0) return `${days} day${days === 1 ? '' : 's'}`;
    const hours = Math.floor(difference / (60 * 60 * 1000));
    if (hours > 0) return `${hours} hour${hours === 1 ? '' : 's'}`;
    const minutes = Math.floor(difference / (60 * 1000));
    if (minutes > 0) return `${minutes} minute${minutes === 1 ? '' : 's'}`;
    return 'less than a minute';
}

export default formatMentions;
