import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { DATASETS_STATES, LAYER_STATES } from 'services/Constants';
import { RootState } from 'store';
import Dataset from 'types/Dataset';
import Project from 'types/Project';
import { DatasetId, SourceFileId } from 'types/common';
import * as geojsonUtils from 'geojsonUtils';
import { SourceFile, getBaseName } from 'types/SourceFile';
import { View } from 'types/serialization/View';
import { getMinMax } from './buildLayerState';

export type State = {
    datasets: Dataset[];
    sourceFiles: Record<DatasetId, SourceFile[]>;
    currentProject: Project;
    clickedDataset: DatasetId;
    clickedSourceFile: SourceFileId;
    state: DATASETS_STATES;

    seismicDataset: DatasetId;
    seismicSourceFile: SourceFileId;
};

const initialState: State = {
    datasets: [],
    sourceFiles: {},
    currentProject: undefined,
    clickedDataset: undefined,
    clickedSourceFile: undefined,
    state: undefined,

    seismicDataset: undefined,
    seismicSourceFile: undefined,
};

function getDataset(state: State, id: DatasetId): Dataset | null {
    const idx = state.datasets.findIndex((ds) => ds.id === id);
    if (idx >= 0) {
        return state.datasets[idx];
    }

    return null;
}

function computeElevationRange(datasets: Dataset[]) {
    let min = +Infinity;
    let max = -Infinity;

    for (const dataset of datasets) {
        const range = getMinMax(dataset);
        if (range) {
            min = Math.min(min, range.min);
            max = Math.max(max, range.max);
        }
    }

    return { min, max };
}

function findSourceFile(s: State, datasetId: DatasetId, sourceFileId: SourceFileId): SourceFile {
    const sourceFiles = s.sourceFiles[datasetId];
    if (sourceFiles) {
        return sourceFiles.find((sf) => sf.id === sourceFileId);
    }
    return undefined;
}

// Selectors
const self = (store: RootState) => store.datasets;

export const getProjectDatasets = createSelector(self, (s) => s.datasets);
export const getProjectSourceFiles = createSelector(self, (s) => s.sourceFiles);
export const get = (id: DatasetId) => createSelector(self, (s) => getDataset(s, id));
export const getDatasetState = (id: DatasetId) => createSelector(self, (s) => getDataset(s, id).state);
export const getState = createSelector(self, (s) => s.state);
export const getClickedDataset = createSelector(self, (s) => s.clickedDataset);
export const getClickedSourceFile = createSelector(self, (s) => s.clickedSourceFile);
export const currentProject = createSelector(self, (s) => s.currentProject);
export const getSourceFile = (datasetId: DatasetId, sourceFileId: SourceFileId) =>
    createSelector(self, (s) => findSourceFile(s, datasetId, sourceFileId));
export const getSourceFiles = (id: DatasetId) => createSelector(self, (s) => s.sourceFiles[id]);
export const getSourceFilename = (datasetId: DatasetId, sourceFileId: SourceFileId) =>
    createSelector(self, (s) => {
        const sourceFile = findSourceFile(s, datasetId, sourceFileId);
        return getBaseName(sourceFile);
    });
export const totalElevationRange = createSelector(
    (s) => self(s).datasets,
    (datasets) => computeElevationRange(datasets)
);
export const getDatasetFromSourceFileId = (id: SourceFileId) =>
    createSelector(self, (s) => {
        for (const [datasetId, files] of Object.entries(s.sourceFiles)) {
            if (files.some((file) => file.id === id)) {
                return getDataset(s, datasetId);
            }
        }
        return null;
    });

export const getProjectVolume = createSelector(self, (s) => {
    const geometry = s.currentProject?.geometry;
    if (geometry) {
        const crs = `EPSG:${s.currentProject.projection}`;
        const extent = geojsonUtils.computeExtent(geometry, 'EPSG:4326', crs);
        const range = computeElevationRange(s.datasets);
        return extent.toBox3(range.min, range.max);
    }
    return null;
});

export const getProjectExtent = createSelector(self, (s) => {
    const geometry = s.currentProject?.geometry;
    if (geometry) {
        const crs = `EPSG:${s.currentProject.projection}`;
        const extent = geojsonUtils.computeExtent(geometry, 'EPSG:4326', crs);
        return extent;
    }
    return null;
});

export const getSeismicDataset = createSelector(self, (s) => getDataset(s, s.seismicDataset));
export const getSeismicSourceFile = createSelector(self, (s) =>
    findSourceFile(s, s.seismicDataset, s.seismicSourceFile)
);

export const getUploading = (id: DatasetId) => createSelector(self, (s) => getDataset(s, id).uploading);

const slice = createSlice({
    name: 'datasets',
    initialState,
    reducers: {
        reset: () => initialState,
        setCurrentProject: (state, action: PayloadAction<Project>) => {
            state.currentProject = action.payload;
        },
        updateCurrentProject: (state, action: PayloadAction<Project>) => {
            state.currentProject = { ...action.payload, user_permissions: state.currentProject.user_permissions };
        },
        addDataset: (state, action: PayloadAction<Dataset>) => {
            state.datasets.push(action.payload);
        },
        removeDataset: (state, action: PayloadAction<Dataset | DatasetId>) => {
            const id = typeof action.payload === 'string' ? action.payload : action.payload.id;

            const idx = state.datasets.findIndex((ds) => ds.id === id);
            state.datasets.splice(idx, 1);
        },
        setState: (state, action: PayloadAction<DATASETS_STATES>) => {
            state.state = action.payload;
        },
        updateDataset: (state, action: PayloadAction<Dataset>) => {
            const id = action.payload.id;
            const idx = state.datasets.findIndex((ds) => ds.id === id);
            state.datasets[idx] = action.payload;
        },
        reorderDatasets: (state, action: PayloadAction<DatasetId[]>) => {
            const payload = action.payload;
            state.datasets.sort((a, b) => {
                const indexA = payload.indexOf(a.id);
                const indexB = payload.indexOf(b.id);
                return (indexA !== -1 ? indexA : 0) - (indexB !== -1 ? indexB : 0);
            });
        },
        setClickedDataset: (state, action: PayloadAction<{ dataset: DatasetId; sourceFile: SourceFileId }>) => {
            state.clickedDataset = action.payload.dataset;
            state.clickedSourceFile = action.payload.sourceFile;
        },
        setSeismicDataset: (state, action: PayloadAction<{ dataset: DatasetId; sourceFile: SourceFileId }>) => {
            state.seismicDataset = action.payload.dataset;
            state.seismicSourceFile = action.payload.sourceFile;
        },
        setDatasetState: (
            state,
            action: PayloadAction<{ dataset: Dataset; state: LAYER_STATES; message?: string }>
        ) => {
            const dataset = getDataset(state, action.payload.dataset.id);
            if (dataset) {
                dataset.state = action.payload.state;
                dataset.state_msg = action.payload.message;
            }
        },
        setDatasetSourceFiles: (state, action: PayloadAction<{ datasetId: DatasetId; sourceFiles: SourceFile[] }>) => {
            const datasetId = action.payload.datasetId;
            const dataset = getDataset(state, datasetId);
            if (dataset) {
                if (state.sourceFiles[datasetId]) {
                    const newFiles: SourceFile[] = [];
                    action.payload.sourceFiles.forEach((f) => {
                        if (state.sourceFiles[datasetId].map((s) => s.id).includes(f.id))
                            newFiles.push({
                                source: f.source ?? state.sourceFiles[datasetId].find((s) => s.id === f.id).source,
                                ...f,
                            });
                        else newFiles.push(f);
                    });
                    state.sourceFiles[datasetId] = newFiles;
                } else state.sourceFiles[datasetId] = action.payload.sourceFiles;
            }
        },
        updateDatasetSourceFile: (state, action: PayloadAction<{ datasetId: DatasetId; sourceFile: SourceFile }>) => {
            const datasetId = action.payload.datasetId;
            const dataset = getDataset(state, datasetId);
            if (dataset) {
                const sourceFiles = state.sourceFiles[datasetId];
                const idx = sourceFiles.findIndex((sf) => sf.id === action.payload.sourceFile.id);
                if (idx !== -1) {
                    if (!action.payload.sourceFile.source)
                        sourceFiles[idx] = { source: sourceFiles[idx].source, ...action.payload.sourceFile };
                    else sourceFiles[idx] = action.payload.sourceFile;
                } else
                    console.log(
                        action.payload.sourceFile,
                        sourceFiles.map((s) => s.id)
                    );
            }
        },
        removeDatasetSourceFiles: (
            state,
            action: PayloadAction<{ datasetId: DatasetId; sourceFileIds: SourceFileId[] }>
        ) => {
            const datasetId = action.payload.datasetId;
            const dataset = getDataset(state, datasetId);
            if (dataset && state.sourceFiles[datasetId]) {
                state.sourceFiles[datasetId] = state.sourceFiles[datasetId].filter(
                    (sf) => !action.payload.sourceFileIds.includes(sf.id)
                );
            }
        },
        setUploading: (state, action: PayloadAction<{ datasetId: DatasetId; sourceFiles: string[] }>) => {
            const id = action.payload.datasetId;
            const idx = state.datasets.findIndex((ds) => ds.id === id);
            state.datasets[idx].uploading = action.payload.sourceFiles;
        },
        loadView: (state, action: PayloadAction<View>) => {
            const selections = action.payload.selections;
            if (selections) {
                state.clickedDataset = selections.clickedDataset;
                state.clickedSourceFile = selections.clickedSourceFile;
                state.seismicDataset = selections.seismicDataset;
                state.seismicSourceFile = selections.seismicSourceFile;
            }
        },
    },
});

export const reducer = slice.reducer;

export const reset = slice.actions.reset;
export const addDataset = slice.actions.addDataset;
export const updateDataset = slice.actions.updateDataset;
export const removeDataset = slice.actions.removeDataset;
export const reorderDatasets = slice.actions.reorderDatasets;
export const setCurrentProject = slice.actions.setCurrentProject;
export const updateCurrentProject = slice.actions.updateCurrentProject;
export const setClickedDataset = slice.actions.setClickedDataset;
export const setSeismicDataset = slice.actions.setSeismicDataset;
export const setDatasetState = slice.actions.setDatasetState;
export const setDatasetSourceFiles = slice.actions.setDatasetSourceFiles;
export const updateDatasetSourceFile = slice.actions.updateDatasetSourceFile;
export const removeDatasetSourceFiles = slice.actions.removeDatasetSourceFiles;
export const setUploading = slice.actions.setUploading;
export const setState = slice.actions.setState;
export const loadView = slice.actions.loadView;
