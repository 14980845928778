import { useDispatch } from 'react-redux';
import { HasMasks, LayerState } from 'types/LayerState';
import * as layersSlice from 'redux/layers';
import * as datasetsSlice from 'redux/datasets';

import { useAppSelector } from 'store';
import Dataset from 'types/Dataset';
import Select from 'components/dropdown/Select';
import { Option } from 'components/Select';

type MaskLayer = LayerState & HasMasks;

export type Props = {
    layerState: MaskLayer;
};

function toOption(layer: MaskLayer, datasets: Dataset[]): Option<string> {
    const dataset = datasets.find((d) => d.id === layer.datasetId);
    return {
        label: dataset.name,
        value: layer.datasetId,
    };
}

const MaskSettings = (props: Props) => {
    const { layerState } = props;

    const dispatch = useDispatch();
    const datasets = useAppSelector(datasetsSlice.getProjectDatasets);
    const possibleMasks = useAppSelector(layersSlice.getMaskableLayers);
    const activeMasks = useAppSelector(layersSlice.getActiveMasks(layerState));

    const displayableMasks = () =>
        possibleMasks
            .filter((l) => l.datasetId !== layerState.datasetId) // Don't mask itself
            .map((l) => toOption(l, datasets));

    function setMasks(masks: string[]) {
        const uniqueValues = [...new Set(masks)];
        dispatch(layersSlice.setActiveMasks({ layer: layerState, value: uniqueValues }));
    }

    return (
        <>
            <Select
                title="Masks"
                icon="fas fa-mask"
                value={activeMasks.map((l) => toOption(l, datasets))}
                options={displayableMasks()}
                onChange={(choices) => {
                    setMasks((choices as Option<string>[]).map((choice) => choice.value));
                }}
                multiValueCounterMessage="Mask"
                isMulti
            />
            <hr />
        </>
    );
};

export default MaskSettings;
