import { IJsonRowNode } from 'flexlayout-react';
import { Ticks } from 'redux/grid';
import type { serializeLayerState } from 'services/serializer';
import { AnnotationId } from 'types/Annotation';
import {
    DatasetGroupId,
    DatasetId,
    ProjectId,
    SerializableColor,
    SerializableVector3,
    SourceFileId,
    Timestamp,
    UUID,
} from 'types/common';

export interface ViewSettings {
    camera: SerializableVector3;
    target: SerializableVector3;
    zoom?: number;
    zScale: number;
    inspectorVisible: boolean;
    axisCompass?: boolean;
    coordinates?: boolean;
}

export type SerializedLayerState = ReturnType<typeof serializeLayerState>;

export type SerializedSourceFileState = {
    datasetId: string;
    visible: boolean;
    id: string;
};

export interface LayerGroup {
    name: string;
    id: string;
    layers: string[];
}

export interface Axisgrid {
    color: SerializableColor;
    ticks: Ticks;
    labels: boolean;
    ceiling: boolean;
    opacity: number;
    visible: boolean;
    verticals: boolean;
    floorLevel: number;
    ceilingLevel: number;
}

export interface SeismicAxisgrid {
    color: SerializableColor;
    ticks: Ticks;
    labels: boolean;
    opacity: number;
    visible: boolean;
}

export interface CrossSection {
    enabled: boolean;
    azimuth: number;
    center: SerializableVector3;
    color: SerializableColor;
    opacity: number;
    invert: boolean;
}

export interface Pointcloud {
    edl: boolean;
    inpainting: boolean;
}

export interface Annotations {
    selected: AnnotationId;
}

export interface Hillshading {
    zenith: number;
    azimuth: number;
    enabled: boolean;
    intensity: number;
}

export interface Contourlines {
    color: SerializableColor;
    enabled: boolean;
    opacity: number;
    primary: number;
    secondary: number;
}

export interface FootprintSettings {
    showLabels: boolean;
}

export interface Layout {
    leftOpen: boolean;
    rightOpen: boolean;
    trayOpen: boolean;
    modelRoot: IJsonRowNode;
}

export interface Selection {
    clickedDataset: DatasetId;
    clickedSourceFile: SourceFileId;
    seismicDataset: DatasetId;
    seismicSourceFile: SourceFileId;
}

export interface SeismicView {
    reversed: boolean;
    maintainDirection: boolean;
    zScale: number;
    snap: boolean;
}

/**
 * The serialized view.
 */
export interface View {
    view?: ViewSettings;
    layers?: Record<DatasetId, SerializedLayerState>;
    sourceFiles?: Record<SourceFileId, SerializedSourceFileState>;
    groups?: Record<DatasetGroupId, LayerGroup>;
    axisgrid?: Axisgrid;
    layerOrder?: DatasetId[];
    pointcloud?: Pointcloud;
    footprintSettings?: FootprintSettings;
    annotations?: Annotations;
    hillshading?: Hillshading;
    contourlines?: Contourlines;
    crossSections?: CrossSection;
    mapresolution?: number;
    layout?: Layout;
    selections?: Selection;
    seismicView: SeismicView;
    seismicGrid: SeismicAxisgrid;
}

export type ViewId = UUID;
export type StoryId = UUID;

export enum VIEW_TYPE {
    PRIVATE = 'private',
    SHARED = 'shared',
    USER_LAST_SESSION = 'user_last_session',
    STORY = 'story',
}

export type SerializedState = {
    id: ViewId;
    name: string;
    description: string;
    project_id: ProjectId;
    created_by_id: UUID;
    updated_by_id: UUID;
    created_at: Timestamp;
    updated_at: Timestamp;
    view?: View;
    view_type?: VIEW_TYPE;
    index?: number;
};

export enum STORY_TYPE {
    PRIVATE = 'private',
    SHARED = 'shared',
}

export type StoryMap = {
    id: StoryId;
    project_id: ProjectId;
    name: string;
    description: string;
    views: SerializedState[];
    viewsLocal?: SerializedState[];
    story_type: STORY_TYPE;
    created_by_id: UUID;
    updated_by_id: UUID;
    created_at: Timestamp;
    updated_at: Timestamp;
};
